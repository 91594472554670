import React, { FunctionComponent, useMemo } from 'react';
import { Frame } from 'framer/build/framer';
import { calcDelay, calcDelta, calcMass } from './utils';

interface Props {
  x: number,
  y: number
}

const baseTransition = {
  type: 'spring',
  damping: 0,
  stiffness: 5,
  mass: 8,
};

const Doodad: FunctionComponent<Props> = ({ x, y, children }) => {
  const transition = useMemo(() => ({
    ...baseTransition,
    mass: calcMass(),
    delay: calcDelay(),
  }), []);
  const animate = useMemo(() => ({ y: y + calcDelta() }), []);
  return (
    <Frame
      x={x}
      y={y}
      background={null}
      width="unset"
      height="unset"
      animate={animate}
      transition={transition}
    >
      {children}
    </Frame>
  );
};

export default Doodad;

import React, {
  FunctionComponent, memo, ReactNode, useCallback, useContext, useEffect, useState,
} from 'react';
import { Frame } from 'framer/build/framer';
import {
  Box, ClickAwayListener, createStyles, Fade, makeStyles, Paper, Popper, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import JourneyBlock from './JourneyBlock';
import { ReactComponent as Chat } from '../../images/journey_chat.svg';
import { ReactComponent as Challenges } from '../../images/journey_challenges.svg';
import { ReactComponent as Endure } from '../../images/journey_endure.svg';
import { ReactComponent as Finish } from '../../images/journey_finish.svg';
import { ReactComponent as HappyLife } from '../../images/journey_happy-life.svg';
import { ReactComponent as Infinity } from '../../images/journey_infinity.svg';
import { ReactComponent as LittleSteps } from '../../images/journey_little_steps.svg';
import { ReactComponent as Loyalty } from '../../images/journey_loyalty.svg';
import { ReactComponent as Match } from '../../images/journey_match.svg';
import { ReactComponent as EachOther } from '../../images/journey_meet-eachother.svg';
import { ReactComponent as Friends } from '../../images/journey_meet-new-friends.svg';
import { ReactComponent as Prices } from '../../images/journey_prices_mobile.svg';
import { ReactComponent as Start } from '../../images/journey_start.svg';
import { ReactComponent as Together } from '../../images/journey_together.svg';
import { AnimationSequenceContext } from '../../providers/AnimationSequenceProvider';
import popoverBackground from '../../images/popover-background@2x.png';
import Doodad from '../Content/Doodad';
import { ReactComponent as Book } from '../../images/book.svg';
import { ReactComponent as Coffee } from '../../images/coffee.svg';
import { ReactComponent as Music } from '../../images/music.svg';
import { ReactComponent as Weights } from '../../images/weights.svg';
import { ReactComponent as Bottle } from '../../images/bottle.svg';
import { ReactComponent as Star } from '../../images/star.svg';
import { ReactComponent as Pictures } from '../../images/pictures.svg';
import { ReactComponent as Talk } from '../../images/talk.svg';
import { ReactComponent as ThumbsUp } from '../../images/thumbs-up.svg';
import { ReactComponent as Cup } from '../../images/cup.svg';
import { ReactComponent as Gift } from '../../images/gift.svg';
import { ReactComponent as Power } from '../../images/power.svg';
import { ReactComponent as CookWoman } from '../../images/cook-woman.svg';
import { ReactComponent as RunningWoman } from '../../images/run-woman.svg';
import useMediaQueryIsDesktop from '../../utils/useMediaQueryIsDesktop';

interface Props {
  numScrollStart: number;
}

const highlightSequence = [3, 7, 10];

const blocks: {
  id: number,
  x: number,
  y: number,
  content: ReactNode,
  title: string,
  description: string,
  doodadContent?: ReactNode
}[] = [{
  id: 0,
  x: 0,
  y: 101.5152,
  content: <Start />,
  title: 'journey.popover.start.title',
  description: 'journey.popover.start.description',
  doodadContent: <CookWoman />,
}, {
  id: 1,
  x: 0,
  y: 215.3727,
  content: <LittleSteps />,
  title: 'journey.popover.littleSteps.title',
  description: 'journey.popover.littleSteps.description',
  doodadContent: <Book />,
}, {
  id: 2,
  x: 136.162,
  y: 304.2172,
  content: <EachOther />,
  title: 'journey.popover.eachOther.title',
  description: 'journey.popover.eachOther.description',
  doodadContent: <Music />,
}, {
  id: 3,
  x: 225.222,
  y: 271.1201,
  content: <Match />,
  title: 'journey.popover.match.title',
  description: 'journey.popover.match.description',
  doodadContent: <Cup />,
}, {
  id: 4,
  x: 271.9166,
  y: 215.3726,
  content: <Challenges />,
  title: 'journey.popover.challenges.title',
  description: 'journey.popover.challenges.description',
  doodadContent: <Weights />,
}, {
  id: 5,
  x: 287.5286,
  y: 101.7502,
  content: <Chat />,
  title: 'journey.popover.chat.title',
  description: 'journey.popover.chat.description',
  doodadContent: <Talk />,
}, {
  id: 7,
  x: 326.3293,
  y: 30.1178,
  content: <Endure />,
  title: 'journey.popover.endure.title',
  description: 'journey.popover.endure.description',
  doodadContent: <Power />,
}, {
  id: 8,
  x: 423.6905,
  y: 20,
  content: <HappyLife />,
  title: 'journey.popover.happyLife.title',
  description: 'journey.popover.happyLife.description',
  doodadContent: <ThumbsUp />,
}, {
  id: 9,
  x: 559.4451,
  y: 101.7501,
  content: <Prices />,
  title: 'journey.popover.prices.title',
  description: 'journey.popover.prices.description',
  doodadContent: <Gift />,
}, {
  id: 10,
  x: 575.7623,
  y: 215.3726,
  content: <Together />,
  title: 'journey.popover.together.title',
  description: 'journey.popover.together.description',
  doodadContent: <Coffee />,
}, {
  id: 11,
  x: 614.5629,
  y: 271.12,
  content: <Loyalty />,
  title: 'journey.popover.loyalty.title',
  description: 'journey.popover.loyalty.description',
  doodadContent: <Star />,
}, {
  id: 12,
  x: 711.9243,
  y: 271.12,
  content: <Friends />,
  title: 'journey.popover.friends.title',
  description: 'journey.popover.friends.description',
  doodadContent: <Pictures />,
}, {
  id: 13,
  x: 847.6789,
  y: 201.1621,
  content: <Infinity />,
  title: 'journey.popover.infinity.title',
  description: 'journey.popover.infinity.description',
  doodadContent: <Bottle />,
}, {
  id: 14,
  x: 858.0458,
  y: 150.0959,
  content: <Finish />,
  title: 'journey.popover.finish.title',
  description: 'journey.popover.finish.description',
  doodadContent: <RunningWoman />,
}].reverse();

const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 80,
    paddingLeft: 8,
    flex: 0,
  },
  paper: {
    backgroundImage: `url(${popoverBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionX: 'right',
    maxWidth: theme.breakpoints.values.sm * 0.781,
  },
  image: {
    width: '100%',
  },
  popover: {
    pointerEvents: 'none',
  },
}));

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 1.5,
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: 'beforeChildren',
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const Journey:FunctionComponent<Props> = ({ numScrollStart }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedId, setSelectedId] = React.useState<number>();
  const [popOverContent, setPopOverContent] = useState<
    {title?: string, description?: string, doodadContent?: ReactNode}
  >({});
  const { index, pause, resume } = useContext(AnimationSequenceContext);
  const classes = useStyles();
  const isDesktop = useMediaQueryIsDesktop();

  const handleClose = useCallback(() => {
    if (anchorEl) {
      setAnchorEl(null);
      setSelectedId(undefined);
      resume?.();
    }
  }, [anchorEl]);

  const handleClick = useCallback((
    title: string,
    description: string,
    doodadContent?: ReactNode,
    id?: number,
  ) => (
    event: any,
  ) => {
    event.stopPropagation();
    if (isDesktop) {
      return;
    }
    if (selectedId === id) {
      handleClose();
      return;
    }
    setPopOverContent({ title, description, doodadContent });
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
    pause?.();
  }, [selectedId, handleClose]);

  const handleHover = useCallback((
    title: string,
    description: string,
    doodadContent?: ReactNode,
    id?: number,
  ) => (
    event: any,
  ) => {
    setPopOverContent({ title, description, doodadContent });
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
    pause?.();
  }, []);

  // Close popover on scrolling to prevent elastic scroll issues on ios
  useEffect(() => {
    if (isDesktop) {
      return;
    }
    handleClose();
  }, [numScrollStart]);

  return (
    <>
      <Frame background={null} variants={containerVariants} initial="hidden" animate="show">
        {blocks.map(({
          id,
          x,
          y,
          content,
          title,
          description,
          doodadContent,
        }) => (
          <Frame key={id} background={null} x={x} y={y} variants={itemVariants}>
            <JourneyBlock
              onClick={handleClick(title, description, doodadContent, id)}
              onHoverStart={handleHover(title, description, doodadContent, id)}
              onHoverEnd={handleClose}
              highlight={id === highlightSequence[index % highlightSequence.length]}
            >
              {content}
            </JourneyBlock>
          </Frame>
        ))}
      </Frame>
      <Popper
        placement="bottom"
        open={!!anchorEl}
        keepMounted
        anchorEl={anchorEl}
        className={isDesktop ? classes.popover : ''}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            rootBoundary: 'window',
            boundariesElement: 'scrollParent',
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Fade in={!!anchorEl}>
            <Paper className={classes.paper}>
              <Box padding={3} className={classes.container}>
                <Box className={classes.textContainer}>
                  <Box>
                    <Typography variant="h6" color="primary" gutterBottom>{t(popOverContent?.title ?? '')}</Typography>
                    <Typography variant="body2" color="textPrimary">{t(popOverContent?.description ?? '')}</Typography>
                  </Box>
                </Box>
                <Box className={classes.imageContainer}>
                  <Doodad x={0} y={0}>
                    <Box className={classes.image}>
                      {popOverContent.doodadContent}
                    </Box>
                  </Doodad>
                </Box>
              </Box>
            </Paper>
          </Fade>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default memo(Journey);

import { useEffect, useState } from 'react';

export default function useContainerDimensions(myRef: any, dependency: boolean) {
  const getDimensions = () => ({
    width: myRef?.current?.getBoundingClientRect()?.width,
    height: myRef?.current?.getBoundingClientRect()?.height,
  });

  const [dimensions, setDimensions] = useState<
    {width?: number, height?: number}>({});

  useEffect(() => {
    setDimensions(getDimensions());
  }, [dependency]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef.current, myRef?.current?.getBoundingClientRect]);

  return dimensions;
}

import React, { FunctionComponent, memo } from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { ReactComponent as Logo } from '../../images/logo.svg';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 16,
  },
  contact: {
    color: theme.palette.common.white,
  },
  logo: {
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

const Footer: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.contact} />
      <a href="https://www.easyfit4all.nl/" target="_blank" rel="noreferrer">
        <Logo className={classes.logo} />
      </a>
    </Box>
  );
};

export default memo(Footer);

import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Frame } from 'framer/build/framer';
import FoodContent from './FoodContent';
import SportContent from './SportContent';
import RelaxContent from './RelaxContent';

const useStyles = makeStyles(() => createStyles({
  container: {
    position: 'relative',
  },
}));

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const itemTransition = { duration: 2 };

const Content = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Frame
        variants={containerVariants}
        initial="hidden"
        animate="show"
        background={null}
      >
        <Frame
          variants={itemVariants}
          transition={itemTransition}
          x={86}
          y={44}
          background={null}
        >
          <FoodContent />
        </Frame>
        <Frame
          variants={itemVariants}
          transition={itemTransition}
          x={324}
          y={112}
          background={null}
        >
          <SportContent />
        </Frame>
        <Frame
          variants={itemVariants}
          transition={itemTransition}
          x={625}
          y={2}
          background={null}
        >
          <RelaxContent />
        </Frame>
      </Frame>
    </Box>
  );
};

export default Content;

import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import './App.css';
import {
  Box, createMuiTheme, createStyles, makeStyles, ThemeProvider,
} from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import i18n from 'i18next';
import Journey from './components/Journey/Journey';
import Background from './components/Background/background';
import Content from './components/Content/Content';
import useDisablePinchZoomEffect from './utils/useDisablePinchZoom';
import { AnimationSequenceProvider } from './providers/AnimationSequenceProvider';
import { cloudSliderHeight, journeyWidth, padding } from './constants';
import useJourneyScale from './utils/useJourneyScale';
import FactCloudSlider from './components/CloudSlider/FactCloudSlider';

const useStyles = makeStyles(() => createStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
  background: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 'calc(100% + 60px)',
  },
  cloudSlider: {
    display: 'flex',
    width: '100%',
    paddingTop: 16,
    minHeight: cloudSliderHeight,
  },
  container: {
    display: 'flex',
    flex: 1,
    paddingTop: 64,
    width: '100%',
    minHeight: cloudSliderHeight + padding, // 385
  },
  scrollContainer: {
    display: 'flex',
    position: 'relative',
    '@media screen and (min-width: 960px)': {
      justifyContent: 'center',
    },
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  contentWrapper: {
    minWidth: journeyWidth,
    paddingLeft: padding,
    paddingRight: padding,
    height: '100%',
    position: 'absolute',
    overflowX: 'hidden',
    overflowY: 'hidden',
    transformOrigin: 'top center',
  },
}));

const theme = createMuiTheme({
  shape: {
    borderRadius: 24,
  },
  palette: {
    primary: {
      main: '#5b9075',
    },
    secondary: {
      main: '#dc9b48',
    },
    text: {
      primary: '#565b5a',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h6: {
      fontWeight: 'bold',
    },
    body2: {
      fontFamily: 'Rubik',
    },
  },
});

function App() {
  useDisablePinchZoomEffect();
  const [numScrollStart, setNumScrollStart] = useState(0);
  const classes = useStyles();
  const journeyScale = useJourneyScale();

  /**
   * TODO We'll have to use scaling because the journey object itself
   *  is build up of absolutely positioned elements. These would
   *  all have to be refactored to relative units for normal
   *  scaling to work.
   */
  const journeyContainerStyle = useMemo(() => ({
    transform: `scale3d(${journeyScale}, ${journeyScale}, ${journeyScale})`,
  }), [journeyScale]);

  const renderView = ({ style }) => (
    <div
      className={classes.scrollContainer}
      style={{ ...style, overflowY: 'hidden' }}
    />
  );

  useEffect(() => {
    fetch('locale/nl/translation.json')
      .then((r) => r.json())
      .then((data) => {
        if (!data) {
          // eslint-disable-next-line no-console
          console.warn('Could not find dutch translation file');
        }
        i18n.addResourceBundle('nl', 'translation', data, true, true);
      });
  }, []);

  const handleScroll = useCallback(() => {
    setNumScrollStart(numScrollStart + 1);
  }, [numScrollStart]);

  return (
    <ThemeProvider theme={theme}>
      <AnimationSequenceProvider>
        <Box className={classes.root}>
          <Box className={classes.background}>
            <Background />
          </Box>
          <Box className={classes.wrapper}>
            <Box className={classes.cloudSlider}>
              <FactCloudSlider />
            </Box>
            <Scrollbars
              id="scrollbar"
              autoHide
              className={classes.container}
              renderView={renderView}
              onScrollStart={handleScroll}
            >
              <Box
                id="container"
                className={classes.contentWrapper}
                style={journeyContainerStyle}
              >
                <Content />
                <Journey numScrollStart={numScrollStart} />
              </Box>
            </Scrollbars>
          </Box>
        </Box>
      </AnimationSequenceProvider>
    </ThemeProvider>
  );
}

export default App;

import React, { FunctionComponent, memo, ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  container: {
    position: 'absolute',
    width: '100%',
    minHeight: 300,
    overflow: 'hidden',
    height: '100%',
  },
}));

interface Props {
  children: ReactNode
}

const CloudSlider: FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {children}
    </div>
  );
};

export default memo(CloudSlider);

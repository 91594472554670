import React, {
  FunctionComponent,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Frame, FrameProps } from 'framer/build/framer';
import { AnimationSequenceContext } from '../../providers/AnimationSequenceProvider';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onClick?: FrameProps['onClick']
  children: ReactNode,
  highlight?: boolean;
  onHoverStart?: FrameProps['onHoverStart'];
  onHoverEnd?: FrameProps['onHoverEnd'];
}

const hoverAnimation = {
  scale: 1.1,
  filter: 'brightness(1.2) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))',
};

const tapAnimation = {
  scale: 0.95,
  filter: 'brightness(0.9) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))',
};

const variants = {
  default: {
    rotate: 0,
    scale: 1,
    filter: 'brightness(1) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))',
    y: 0,
  },
  highlight: {
    rotate: [0, 3, -5, 5, -5, 5, -5, 3, 0],
    scale: 1.05,
    filter: 'brightness(1.2) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))',
    y: -4,
  },
};

const transition = {
  type: 'spring',
  duration: 0.55,
};

const JourneyBlock: FunctionComponent<Props> = ({
  onClick, children, highlight, onHoverStart, onHoverEnd,
}) => {
  const [animateVariant, setAnimateVariant] = useState<'default' | 'highlight'>('default');
  const [canHighlight, setCanHighlight] = useState<boolean>(true);
  const {
    index, paused, pause, resume,
  } = useContext(AnimationSequenceContext);

  useEffect(() => {
    if (canHighlight && highlight && animateVariant !== 'highlight' && !paused) {
      setAnimateVariant('highlight');
      setCanHighlight(false);
    }
  }, [highlight, paused, canHighlight]);

  // disable highlighting until next animation sequence step
  useEffect(() => {
    if (paused) {
      setCanHighlight(false);
    }
  }, [paused]);

  useEffect(() => { setCanHighlight(true); }, [index]);

  const handleAnimationComplete = () => {
    setAnimateVariant('default');
  };

  const handleHoverStart: FrameProps['onHoverStart'] = (event, info) => {
    pause?.();
    onHoverStart?.(event, info);
  };

  const handleHoverEnd: FrameProps['onHoverEnd'] = (event, info) => {
    resume?.();
    onHoverEnd?.(event, info);
  };

  return (
    <>
      <Frame
        onClick={onClick}
        onHoverStart={handleHoverStart}
        onHoverEnd={handleHoverEnd}
        whileHover={hoverAnimation}
        whileTap={tapAnimation}
        variants={variants}
        transition={transition}
        animate={animateVariant}
        width="unset"
        height="unset"
        background={null}
        onAnimationComplete={handleAnimationComplete}
      >
        {children}
      </Frame>
    </>
  );
};

export default memo(JourneyBlock);

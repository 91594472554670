import { useCallback, useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';
import {
  cloudSliderHeight, journeyHeight, journeyScaleMin, journeyWidth, padding,
} from '../constants';

export default function useJourneyScale(): number {
  const { width, height } = useWindowSize();
  const [journeyContainerScale, setJourneyContainerScale] = useState(1);

  const calculateSliderScaleBasedOnHeight = useCallback(
    () => Math.max(
      ((height ?? 0) - padding * 4 - cloudSliderHeight) / journeyHeight, journeyScaleMin,
    ),
    [height],
  );

  const calculateSliderScaleBasedOnWidth = useCallback(
    () => Math.max(((width ?? 0) - padding * 2) / journeyWidth, 1),
    [width],
  );

  useEffect(() => {
    /**
     * find the smallest scale factor to make sure the journey always fits
     * within its container.
     */
    const scale = Math.min(
      calculateSliderScaleBasedOnWidth(),
      calculateSliderScaleBasedOnHeight(),
    );
    setJourneyContainerScale(scale);
  }, [width, height]);

  return journeyContainerScale;
}

import { Link } from '@material-ui/core';
import React, {
  FunctionComponent, memo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import VideoModal from './VideoModal';

interface Props {
  className?: string;
}

const VideoButton: FunctionComponent<Props> = ({ className }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = (event:any) => {
    event?.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        color="primary"
        className={className}
        href="#"
        variant="body2"
        onClick={handleClick}
      >
        {t('video.readMore')}
      </Link>
      <VideoModal open={open} onClose={handleClose} />
    </>
  );
};

export default memo(VideoButton);

import React, { FunctionComponent, useMemo } from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Frame } from 'framer/build/framer';
import { ReactComponent as Food } from '../../images/info_food.svg';
import { ReactComponent as Egg } from '../../images/egg.svg';
import { ReactComponent as Steak } from '../../images/steak.svg';
import { ReactComponent as Avocado } from '../../images/avocado.svg';
import { ReactComponent as Apple } from '../../images/apple.svg';
import { ReactComponent as PlayIcon } from '../../images/play-icon.svg';
import Doodad from './Doodad';
import VideoButton from './VideoButton';

const useStyles = makeStyles(() => createStyles({
  container: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    top: 191,
    left: 32 + 11,
  },
  playIcon: {
    position: 'absolute',
    top: 188,
    left: 33,
  },
}));

const FoodContent:FunctionComponent = () => {
  const classes = useStyles();
  const doodads = useMemo(() => [{
    id: 0,
    x: 87,
    y: 0,
    content: <Egg />,
  }, {
    id: 1,
    x: 110,
    y: 53,
    content: <Steak />,
  }, {
    id: 2,
    x: 70,
    y: 73,
    content: <Avocado />,
  }, {
    id: 3,
    x: 158,
    y: 88,
    content: <Apple />,
  }], []);

  return (
    <Box className={classes.container}>
      <Frame background={null} x={0} y={47}>
        <Food />
      </Frame>
      {doodads.map(({
        id,
        x,
        y,
        content,
      }) => <Doodad key={id} x={x} y={y}>{content}</Doodad>)}
      <PlayIcon className={classes.playIcon} />
      <VideoButton className={classes.button} />
    </Box>
  );
};

export default FoodContent;

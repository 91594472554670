import React, {
  memo,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Frame, FrameProps } from 'framer';
import CloudSlider from './CloudSlider';
import Cloud from './Cloud';
import { ReactComponent as FactCloudOne } from '../../images/fact_cloud_one.svg';
import { ReactComponent as FactCloudCorona } from '../../images/fact_cloud_corona.svg';
import { ReactComponent as FactCloudTwo } from '../../images/fact_cloud_two.svg';
import { ReactComponent as FactCloudThree } from '../../images/fact_cloud_three.svg';
import { ReactComponent as FactCloudFour } from '../../images/fact_cloud_four.svg';
import { ReactComponent as FactCloudFive } from '../../images/fact_cloud_five.svg';
import { factCloudSliderDuration } from '../../constants';
import tapImage from '../../images/HandTappingAnim_NoBG.gif';

const clouds = [{
  id: 0,
  content: <FactCloudOne />,
  button: {
    left: 125,
    bottom: 16,
  },
}, {
  id: 2,
  content: <FactCloudTwo />,
  button: {
    left: 125,
    bottom: 16,
  },
}, {
  id: 3,
  content: <FactCloudThree />,
  button: {
    left: 125,
    bottom: 16,
  },
}, {
  id: 4,
  content: <FactCloudFour />,
  button: {
    left: 125,
    bottom: 16,
  },
}, {
  id: 5,
  content: <FactCloudFive />,
  button: {
    left: 125,
    bottom: 16,
  },
}, {
  id: 1,
  content: <FactCloudCorona />,
  button: {
    left: 125,
    bottom: 16,
  },
}];

const useStyles = makeStyles(() => createStyles({
  tapImageContainer: {
    position: 'absolute',
    right: 90,
    bottom: 8,
  },
  tapImage: {
    width: 72,
    opacity: 0.4,
    filter: 'invert()',
  },
}));

const tapImageAnimation = {
  opacity: [0, 1, 1, 1, 1, 0],
};

const tapImageTransition: FrameProps['transition'] = {
  duration: 2,
  delay: 0.6,
  repeatDelay: factCloudSliderDuration - 2,
  repeat: Infinity,
};

const FactCloudSlider = () => {
  const [cloudIndex, setCloudIndex] = useState<number | undefined>(undefined);
  const classes = useStyles();

  const handleDelete = useCallback(() => {
    const result = ((cloudIndex ?? 0) + 1) % clouds.length;
    setCloudIndex(result);
  }, [cloudIndex]);

  useEffect(() => {
    if (cloudIndex === undefined) {
      handleDelete();
    }
  }, [cloudIndex]);

  const cloud = useMemo(() => {
    if (cloudIndex === undefined) {
      return undefined;
    }
    return clouds[cloudIndex];
  }, [cloudIndex]);

  return (
    <CloudSlider>
      <Cloud
        duration={factCloudSliderDuration}
        onDelete={handleDelete}
      >
        <Box position="relative">
          {cloud?.content}
          <Frame
            background={null}
            width="unset"
            height="unset"
            className={classes.tapImageContainer}
            animate={tapImageAnimation}
            transition={tapImageTransition}
          >
            <img alt="tap" src={tapImage} className={classes.tapImage} />
          </Frame>
        </Box>
      </Cloud>
    </CloudSlider>
  );
};

export default memo(FactCloudSlider);

import * as React from 'react';
import {
  FunctionComponent, memo, ReactNode, useCallback, useEffect, useMemo,
} from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Frame } from 'framer/build/framer';
import { useAnimation } from 'framer-motion';
import useWindowSize from '../../utils/useWindowSize';
import { cloudSliderEndScale, cloudSliderStartScale, padding } from '../../constants';

interface Props {
  duration?: number,
  repeatDelay?: number,
  delay?: number,
  children: ReactNode
  startScale?: number;
  endScale?: number;
  onDelete?: () => void;
  open?: boolean;
}

const CLOUD_WIDTH = 285;

const useStyles = makeStyles(() => createStyles({
  cloud: {
    width: CLOUD_WIDTH,
  },
}));

const hoverAnimation = {
  scale: 1.05,
  filter: 'brightness(1.03)',
};

const clickAnimation = {
  scale: 0.95,
  filter: 'brightness(0.98)',
};

const Cloud: FunctionComponent<Props> = ({
  duration,
  delay,
  repeatDelay,
  children,
  startScale = cloudSliderStartScale,
  endScale = cloudSliderEndScale,
  onDelete,
}) => {
  const animationControls = useAnimation();
  const { width } = useWindowSize();

  const animation = useMemo(() => ({
    x: Math.max(padding, (width ?? 0) * endScale - CLOUD_WIDTH / 2),
    opacity: [0, ...[...Array(12)].map(() => 1), 0],
    scale: [0.9, 0.97, 0.99, ...[...Array(12)].map(() => 1), 0.99, 0.97, 0.9],
  }), [children, endScale]);

  const initial = useMemo(() => ({
    x: (width ?? 0) * startScale - CLOUD_WIDTH / 2,
    opacity: 0,
    scale: 0.9,
  }), [width]);

  const handleDelete = () => {
    animationControls.set(initial);
    animationControls.stop();
    onDelete?.();
  };

  const handleClick = useCallback(async () => {
    await animationControls.start({
      transition: { duration: 0.2 },
      opacity: 0,
    });
  }, [animationControls]);

  useEffect(() => {
    animationControls.start(animation);
  }, [animation, children]);

  const transition = useMemo(() => ({
    delay,
    duration,
    repeatDelay,
    ease: 'linear',
    repeat: onDelete ? undefined : Infinity,
  }), []);

  const classes = useStyles();

  if (!width) {
    return null;
  }

  return (
    <Frame
      className={classes.cloud}
      background={null}
      initial={initial}
      animate={animationControls}
      transition={transition}
      onClick={handleClick}
      onAnimationComplete={handleDelete}
    >
      <Box position="relative" padding={1}>
        <Frame
          background={null}
          width="unset"
          height="unset"
          whileHover={hoverAnimation}
          whileTap={clickAnimation}
        >
          {children}
        </Frame>
      </Box>
    </Frame>

  );
};

export default memo(Cloud);

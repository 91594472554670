export const journeyScaleMin = 1;
export const journeyWidth = 960 * journeyScaleMin;
export const journeyHeight = 385 * journeyScaleMin;

export const cloudSliderHeight = 152; // originally 168
export const padding = 16;

export const cloudSliderStartScale = 0.525;
export const cloudSliderEndScale = 0.475;

export const factCloudSliderDuration = 6;
export const factCloudSliderRepeatDelay = 1;

export const emptyCloudSliderDuration = 240;
export const emptyCloudSliderDurationVariance = 20;
export const emptyCloudSliderRepeatDelay = 2;

export const vimeoLink = 'https://player.vimeo.com/video/540180112?autopause=0&autoplay=1';

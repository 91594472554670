import React from 'react';
import { Box } from '@material-ui/core';
import CloudSlider from './CloudSlider';
import Cloud from './Cloud';
import { ReactComponent as EmptyCloud } from '../../images/empty_cloud.svg';
import {
  cloudSliderHeight,
  emptyCloudSliderDuration,
  emptyCloudSliderDurationVariance,
  emptyCloudSliderRepeatDelay, padding,
} from '../../constants';

const part = 1 / 6;

const clouds = [...Array(6)].map((_, index) => {
  const startScale = (index + 1) * part;
  const endScale = index * part;
  const diff = startScale - endScale;
  return ({
    id: index,
    delay: 4 * index,
    duration: Math.random() * emptyCloudSliderDurationVariance + emptyCloudSliderDuration * diff,
    scale: Math.random() * 0.5 + 0.5,
    startScale,
    endScale,
    y: (((index + 2) % 3) / 3) * (cloudSliderHeight * 2 - padding * 2) + padding,
  });
});

const EmptyCloudSlider = () => (
  <CloudSlider>
    {clouds.map(({
      id, delay, duration, scale, y, startScale, endScale,
    }) => (
      <Cloud
        key={id}
        duration={duration}
        repeatDelay={emptyCloudSliderRepeatDelay}
        delay={delay}
        endScale={endScale}
        startScale={startScale}
      >
        <Box
          key={id}
          id="absolute-movement-matje"
          position="absolute"
          style={{
            transform: `scale(${scale}) translateY(${y}px)`,
          }}
        >
          <EmptyCloud />
        </Box>
      </Cloud>
    ))}
  </CloudSlider>
);

export default EmptyCloudSlider;

import React, {
  createContext,
  FunctionComponent, useCallback,
  useState,
} from 'react';
import useInterval from '../utils/useInterval';

export interface AnimationSequence {
  index: number,
  paused: boolean,
}

const DEFAULT_SEQUENCE: AnimationSequence = {
  index: 0,
  paused: false,
};

export const AnimationSequenceContext = createContext<AnimationSequence & {
  pause?:() => void;
  resume?:() => void;
    }>(DEFAULT_SEQUENCE);

/**
 * Provides a common animation pulse that multiple object
 * can listen to in order to align their own animations. In
 * addition, this animation pulse can be paused.
 *
 * @param children
 * @constructor
 */
export const AnimationSequenceProvider: FunctionComponent = ({
  children,
}) => {
  const [index, setIndex] = useState<number>(0);
  const [paused, setPaused] = useState<boolean>(false);

  const pause = useCallback(() => {
    if (!paused) {
      setPaused(true);
    }
  }, [paused]);

  const resume = useCallback(() => {
    if (paused) {
      setPaused(false);
    }
  }, [paused]);

  const increment = useCallback(() => {
    if (!paused) {
      setIndex(index + 1);
    }
  }, [index, paused]);

  useInterval(increment, 6000);

  return (
    <AnimationSequenceContext.Provider value={{
      index, paused, pause, resume,
    }}
    >
      {children}
    </AnimationSequenceContext.Provider>
  );
};

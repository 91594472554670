import React, { FunctionComponent, useMemo } from 'react';
import {
  Box, createStyles, makeStyles,
} from '@material-ui/core';
import { Frame } from 'framer/build/framer';
import { ReactComponent as Sport } from '../../images/info_move.svg';
import { ReactComponent as Weights } from '../../images/weights.svg';
import { ReactComponent as Rope } from '../../images/jumping-rope.svg';
import { ReactComponent as Bottle } from '../../images/bottle.svg';
import Doodad from './Doodad';
import { ReactComponent as PlayIcon } from '../../images/play-icon.svg';
import VideoButton from './VideoButton';

const useStyles = makeStyles(() => createStyles({
  container: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    top: 141,
    left: 82,
  },
  playIcon: {
    position: 'absolute',
    top: 139,
    left: 82 - 8,
  },
}));

const SportContent:FunctionComponent = () => {
  const classes = useStyles();
  const doodads = useMemo(() => [{
    id: 0,
    x: 58,
    y: 192,
    content: <Weights />,
  }, {
    id: 1,
    x: 116,
    y: 177,
    content: <Rope />,
  }, {
    id: 2,
    x: 208,
    y: 161,
    content: <Bottle />,
  }], []);

  return (
    <Box className={classes.container}>
      <Frame background={null}>
        <Sport />
      </Frame>
      {doodads.map(({
        id,
        x,
        y,
        content,
      }) => <Doodad key={id} x={x} y={y}>{content}</Doodad>)}
      <PlayIcon className={classes.playIcon} />
      <VideoButton className={classes.button} />
    </Box>
  );
};

export default SportContent;

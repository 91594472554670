import React, {
  FunctionComponent, useMemo, useRef, useState,
} from 'react';
import { Dialog, DialogProps, PaperProps } from '@material-ui/core';
import { vimeoLink } from '../../constants';
import useContainerDimensions from '../../utils/useContainerDimensions';

interface Props extends Pick<DialogProps, 'open' | 'onClose'> {}

const paperProps: PaperProps = {
  style: { borderWidth: 8, borderColor: '#fff', borderStyle: 'solid' },
};

const VideoModal: FunctionComponent<Props> = ({ open, onClose }) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [entering, setEntering] = useState<boolean>(false);
  const { width } = useContainerDimensions(ref, entering);
  const height = useMemo(() => (width ?? 960) * (9 / 16), [width, open]);

  const handleOnEntering = () => {
    setEntering(true);
  };

  const handleEntered = () => {
    setEntering(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onEntering={handleOnEntering}
      onEntered={handleEntered}
      maxWidth="md"
      fullWidth
      PaperProps={paperProps}
    >
      <iframe
        title="video"
        src={vimeoLink}
        width="100%"
        height={height}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        ref={ref}
      />
    </Dialog>
  );
};

export default VideoModal;

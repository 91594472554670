import React, { FunctionComponent } from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Frame } from 'framer/build/framer';
import { ReactComponent as Relax } from '../../images/info_relax.svg';
import { ReactComponent as PlayIcon } from '../../images/play-icon.svg';
import VideoButton from './VideoButton';

const useStyles = makeStyles(() => createStyles({
  container: {
    position: 'relative',
  },
  buttonContainer: {
    zIndex: 1,
  },
  button: {
    position: 'absolute',
    top: 240,
    left: 88,
    zIndex: 10,
  },
  playIcon: {
    position: 'absolute',
    top: 238,
    left: 76,
  },
}));

const RelaxContent:FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Frame background={null} y={1} className={classes.buttonContainer}>
        <PlayIcon className={classes.playIcon} />
        <VideoButton className={classes.button} />
      </Frame>
      <Frame background={null} y={83}>
        <Relax />
      </Frame>
    </Box>
  );
};

export default RelaxContent;

import React, { FunctionComponent } from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { ReactComponent as Bush } from '../../images/bush.svg';
import EmptyCloudSlider from '../CloudSlider/EmptyCloudSlider';
import Footer from './Footer';

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  sky: {
    flex: 3,
    display: 'flex',
    width: '100%',
    backgroundColor: '#d0e3f5',
    position: 'relative',
  },
  ground: {
    flex: 2,
    display: 'flex',
    width: '100%',
    backgroundColor: '#91ce9b',
    position: 'relative',
  },
  footer: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
}));

const bushes = [{
  id: 0,
  x: '-5%',
  y: -30,
}, {
  id: 1,
  x: '10%',
  y: '75%',
}, {
  id: 2,
  x: '25%',
  y: '15%',
}, {
  id: 3,
  x: '45%',
  y: '55%',
}, {
  id: 4,
  x: '65%',
  y: -30,
}, {
  id: 5,
  x: '90%',
  y: '15%',
}];

const Background:FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.sky}>
        <EmptyCloudSlider />
      </Box>
      <Box className={classes.ground}>
        {bushes.map(({ id, x, y }) => (
          <Box key={id} position="absolute" left={x} top={y}>
            <Bush />
          </Box>
        ))}
      </Box>
      <Box className={classes.footer}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Background;
